exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-company-contact-js": () => import("./../../../src/pages/company/contact.js" /* webpackChunkName: "component---src-pages-company-contact-js" */),
  "component---src-pages-dataprotection-js": () => import("./../../../src/pages/dataprotection.js" /* webpackChunkName: "component---src-pages-dataprotection-js" */),
  "component---src-pages-imprint-js": () => import("./../../../src/pages/imprint.js" /* webpackChunkName: "component---src-pages-imprint-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-services-ai-js": () => import("./../../../src/pages/services/ai.js" /* webpackChunkName: "component---src-pages-services-ai-js" */),
  "component---src-pages-services-app-js": () => import("./../../../src/pages/services/app.js" /* webpackChunkName: "component---src-pages-services-app-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-web-js": () => import("./../../../src/pages/services/web.js" /* webpackChunkName: "component---src-pages-services-web-js" */)
}

